import React, { useEffect } from 'react'
import { FaHome } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import MyNavbar from '../components/MyNavbar';

function FoodInvaders() {
  useEffect(() => {
    document.title = 'Créations - Food Invaders'
  }, []);

  return (
    <>
      <MyNavbar/>
      <div className='pb-20'>
        <div className='pl-5 pt-5'></div>
        <h1 className='text-5xl md:text-6xl text-center font-Gaming mt-10 pb-20'>FOOD INVADERS</h1>

        <img
            className='mx-auto pb-10 w-96'
            src="/images/creations/foodinvaders.png" alt="Food Invaders" />
        
        <div className='flex flex-row items-center'>
          <Link
            className='bg-[#07405B] text-white p-5 mx-auto rounded-xl'
            target='_blank'
            to='https://ulejoyeu.itch.io/food-invaders'>
            Jouer sur itch.io
          </Link>
        </div>
      </div>

      {/* Séparateur */}
      <hr className='border-black my-20 w-1/2 md:w-1/3 mx-auto' style={{ height: '5px' }} />
      
      <div className='py-5 font-poppins px-10 md:px-20 mx-auto md:w-3/4 lg:w-3/5'>
          <h1 className='py-5 text-4xl font-bold font-Gaming'>DESCRIPTION</h1>
          <p className='py-5'>
              Attrapez les aliments "healthy" et éliminez la "junk food" avec votre laser.<br/>
              Evitez les obstacles.
          </p>

          <p className='py-5'>Vous pouvez obtenir des bonus :</p>

          <ul className='py-5 list-disc'>
              <li>le couteau : bonus Super-Lazer,  beaucoup plus rapide, qui vous permet de tout éliminer, même les obstacles</li>
              <li>le bouclier : vous ne recevez aucun dégât pendant une période</li>
              <li>la toque de chef : vous êtes immunisé contre la junk food</li>
              <li>l'étoile Michelin : vous obtenez un bonus de score</li>
          </ul>
          
          <p className='py-5'>Attention aux malus :</p>
          <ul className='py-5 list-disc'>
              <li>le panneau : élimine tous les éléments de la map mais inflige des dégâts</li>
              <li>la cuillère : Le laser est beaucoup plus lent</li>
          </ul>

          <p className='py-5'>Déplacement : ZQSD ou touches fléchées</p>
          <p className='py-5'>Pour tirer au laser : Appuyez sur "Espace"</p>
      </div>
    </>
  )
}

export default FoodInvaders