import React, { useEffect } from 'react'
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function NotFound() {
  useEffect(() => {
    document.title = 'Ulrich Lejoyeux - Erreur 404'
  }, []);

  return (
    <div className='py-10 md:py-20 md:px-20'>
        <h1 className='text-center text-6xl font-bold font-Gaming leading-normal'>
            404
        </h1>
        <img
          className='w-48 mx-auto my-5'
          src='/images/notfound.png' alt='Not found' />
        <h1 className='text-center text-6xl font-bold font-Gaming leading-normal'>
            Cette page n'existe pas
        </h1>

        <div className='flex flex-row items-center pt-10'>
          <Link
              className='bg-[#07405B] text-white p-10 mx-auto rounded-full'
              to='/'>
              <FaHome size={48} />
          </Link>
        </div>
    </div>
  )
}

export default NotFound