import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import FoodInvaders from "./pages/FoodInvaders";
import ChatSpirateur from "./pages/ChatSpirateur";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/creations/foodinvaders' element={<FoodInvaders />} />
        <Route path='/creations/chatspirateur' element={<ChatSpirateur />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
