import React, { useState } from 'react';
import { FaBars, FaWindowClose } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function MyNavbarLink({link, title}) {
  return (
    <li className="py-4 md:py-0 md:mr-6 hover:text-slate-600">
        <Link to={link} className="text-sm uppercase font-semibold w-full">{title}</Link>
    </li>
  );
}

function MyNavbar() {
  const [menuExpanded, setMenuExpanded] = useState(false);

  const toggleNav = () => {
    setMenuExpanded(!menuExpanded);
  };

  return (
    <>
      <header className="bg-white z-50 fixed top-0 w-full shadow">
        <nav
            id="main-nav"
            className="bg-white max-w-5xl mx-auto font-poppins p-6 flex items-center justify-between">

            <a href="#root" className="flex" aria-label="Page d'accueil du portfolio">
                <img
                    src="/ulrich512.png"
                    className="hidden md:w-7 md:inline md:mr-4"
                    alt="" />
                <span className="text-lg lg:text-xl" aria-hidden="true">Ulrich <strong>L.</strong></span>
            </a>

            <button
                aria-label="toggle button"
                aria-expanded={menuExpanded}
                id="main-menu-btn"
                className="cursor w-7 md:hidden"
                onClick={toggleNav}>
                {menuExpanded ? <FaWindowClose/> : <FaBars/>}
            </button>

            <ul
                id="toggled-main-menu"
                className={`w-full absolute top-full left-0 ${menuExpanded ? "" : "-translate-y-full"} -z-10 bg-white text-gray-800 border-b border-gray-200 flex flex-col items-center md:static md:z-10 md:w-min md:transform-none md:border-none md:flex-row`}>
                <MyNavbarLink link="/" title="Accueil"/>
            </ul>
        </nav>
      </header>
      <div className='h-20'></div>
    </>
  )
}

export default MyNavbar;