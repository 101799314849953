import React, { useEffect } from 'react'
import SkillBadge from '../components/home/SkillBadge';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';

import { FaFacebook, FaGithub, FaItchIo, FaLinkedin } from 'react-icons/fa';
import ProjectCard from '../components/home/ProjectCard';
import MyNavbar from '../components/MyNavbar';

function Home() {
    useEffect(() => {
      document.title = 'Ulrich Lejoyeux'
    }, []);

  return (
    <>
        <MyNavbar/>

        {/* Bannière */}
        <div
            className='p-10 md:p-20'>
            <motion.h1
                initial={{ x: -150, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: .75 }}
                className='text-center text-3xl md:text-6xl font-bold font-Gaming leading-normal'>BIENVENUE SUR<br/>LE PORTFOLIO<br/></motion.h1>

            <motion.h1
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: .75 }}
                className='text-center text-4xl md:text-7xl font-bold mt-10 font-Gaming'>D'ULRICH LEJOYEUX</motion.h1>

        </div>
        <div className='px-5 py-10 md:p-20 text-center mx-auto md:w-1/2 font-poppins'>
            Passionné d'informatique et de jeux vidéos, je crois que la simplicité est la clé de la réussite. Mes créations digitales sont axées sur l’amusement et le rétro-gaming.
        </div>
        {/* A propos */}
        <div className='bg-[#07405B] md:flex md:flex-row md:pt-20'>
            <div className='md:w-1/2 px-7 py-16 md:p-20'>
                <h1 className='text-white text-4xl font-bold font-Gaming'>A PROPOS</h1>
                <div className='text-white mt-10 font-poppins'>
                    Je suis ingénieur en informatique et développeur fullstack.<br/><br/>
                    Je suis passionné par l'informatique depuis de nombreuses années, et je présente sur ce site les différents projets que j'ai pu développer.<br/><br/>
                    Je suis actuellement consultant dans l'entreprise Alten depuis 4 ans et j'ai pu effectuer différentes missions de développement, à la fois front-end et backend.
                </div>
            </div>
            <div className='md:w-1/2 pb-14 md:pb-20'>
                <img className='w-3/4 md:mt-20 rounded-full w-64 h-64 md:w-80 md:h-80 object-cover mx-auto text-center' 
                    src="/images/photo_ulrich_lejoyeux.jpg" 
                    alt="Ulrich Lejoyeux" />
            </div>
        </div>
        {/* Galerie de projets */}
        {/* <div className='bg-[#FFC61A] py-16 md:py-20'> */}
        <div className='py-16 bg-white md:py-20'>
            <h1 className='text-center text-4xl font-bold font-Gaming'>GALERIE DE PROJETS</h1>
            <div className='text-center p-10 font-poppins font-bo'>Voici quelques projets que j'ai développé</div>
            <div className='items-center flex flex-col md:flex-row md:justify-around flex-shrink-0'>
                <ProjectCard
                    link='/creations/foodinvaders'
                    imgSrc='/images/creations/foodinvaders.png'
                    imgAlt='Food Invaders'
                    title='FoodInvaders'
                    description='Attrapez les aliments "healthy" et éliminez la "junk food" avec votre laser. Evitez les obstacles.'
                    />     

                <ProjectCard
                    link='/creations/chatspirateur'
                    imgSrc='/images/creations/chatspirateur.png'
                    imgAlt='ChatSpirateur'
                    title='ChatSpirateur'
                    description='Jeu créé pendant une Créajam.<br/>Se joue à deux. Éliminez les chats en les aspirant ou en les expulsant avec un aspirateur.'
                    />
            </div>
        </div>

        {/* Séparateur */}
        <hr className='border-black my-20 w-1/2 md:w-1/3 mx-auto' style={{ height: '5px' }} />

        {/* Technologies */}
        <div className='py-20'>
            <h1 className='text-center text-4xl font-bold font-Gaming'>TECHNOLOGIES</h1>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-20'>
                <SkillBadge
                    src='/images/logos/html5.svg'
                    alt='HTML 5'
                    title='HTML 5' />
                
                <SkillBadge
                    src='/images/logos/css3.svg'
                    alt='CSS 3'
                    title='CSS 3' />

                <SkillBadge
                    src='/images/logos/javascript.svg'
                    alt='JavaScript'
                    title='JavaScript' />
                
                <SkillBadge
                    src='/images/logos/java.svg'
                    alt='Java'
                    title='Java' />

                <SkillBadge
                    src='/images/logos/python.svg'
                    alt='Python'
                    title='Python' />
                
                <SkillBadge
                    src='/images/logos/git.svg'
                    alt='Git'
                    title='Git' />
                
                <SkillBadge
                    src='/images/logos/godot.svg'
                    alt='Godot'
                    title='Godot' />
            </div>
        </div>
        {/* Contact */}
        <div className='py-10 px-5 md:p-20 bg-[#0B1320]'>
            <div className='font-Gaming text-3xl md:text-4xl font-bold text-white leading-loose mb-10'>
                RETROUVEZ-MOI<br/>
                SUR LES RESEAUX !<br/>
            </div>
            <div className='flex flex-row text-white'>
                <Link className='mr-5' to='https://www.facebook.com/ulrich.lejoyeux' target='_blank'>
                    <FaFacebook size={48} />
                </Link>
                <Link className='mr-5' to='https://www.linkedin.com/in/lejoyeux-ulrich-614772132/' target='_blank'>
                    <FaLinkedin size={48} />
                </Link>
                <Link className='mr-5' to='https://ulejoyeu.itch.io' target='_blank'>
                    <FaItchIo size={48} />
                </Link>
                <Link className='mr-5' to='https://github.com/ulejoyeu' target='_blank'>
                    <FaGithub size={48} />
                </Link>
            </div>
        </div>
    </>
  )
}

export default Home;