import React from 'react'

function SkillBadge({src, alt, title}) {
  return (
    <div className='flex flex-col py-5 justify-center items-center'>
        <img 
            className='w-28 md:w-40'
            src={src}
            alt={alt} />
        <h2 className='pt-5 font-bold italic text-2xl'>{title}</h2>
    </div>
  )
}

export default SkillBadge