import React, { useEffect } from 'react'
import { FaHome } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import MyNavbar from '../components/MyNavbar';

function ChatSpirateur() {
  useEffect(() => {
    document.title = 'Créations - ChatSpirateur'
  }, []);

  return (
    <>
        <MyNavbar/>

        <div className='pb-20'>
            <div className='pl-5 pt-5'></div>
            <h1 className='text-4xl md:text-6xl text-center font-Gaming mt-20 md:mt-10 pb-20'>CHATSPIRATEUR</h1>

            <img
                className='mx-auto pb-10 w-96'
                src="/images/creations/chatspirateur.png" alt="ChatSpirateur" />
        
            <div className='flex flex-row items-center'>
            <Link
                className='bg-[#07405B] text-white p-5 mx-auto rounded-xl'
                target='_blank'
                to='https://gmaquina.itch.io/chat-spirateur'>
                Jouer sur itch.io
            </Link>
            </div>
        </div>

        {/* Séparateur */}
        <hr className='border-black my-20 w-1/2 md:w-1/3 mx-auto' style={{ height: '5px' }} />

        <div className='py-5 font-poppins px-10 md:px-20 mx-auto md:w-3/4 lg:w-3/5'>
          <h1 className='py-5 text-4xl font-bold font-Gaming'>DESCRIPTION</h1>

          <p className='py-5'>
            Jeu créé pendant une Créajam.<br/>
            Se joue à deux.<br/>
            Éliminez les chats en les aspirant ou en les expulsant avec un aspirateur.
          </p>

          <p className='py-5'>
            Le joueur bleu se déplace en utilisant les touches fléchées du clavier. 
            Il peut aspirer des chats avec la touche "Entrée". 
            Lorsqu'il aspire un chat, les joueurs remportent des points et le chat est stocké dans l'aspirateur. 
            Il peut ensuite être expulsé par le joueur rouge
          </p>

          <p className='py-5'>
            Le joueur rouge se déplace en utilisant les touches ZQSD. 
            Lorsque des chats sont présents dans l'aspirateur, il peut les expulser en appuyant sur la touche "Espace". 
            Si le chat expulsé heurte un autre chat, celui-ci est éliminé et les joueurs remportent des points.
          </p>

          <p className='py-5'>
            Les deux joueurs sont reliés par une chaine. Ils doivent doivent coordonner leurs mouvements pour avancer dans le jeu.
          </p>
        </div>
    </>
  )
}

export default ChatSpirateur